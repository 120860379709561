@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7V1s.ttf) format('truetype');
}
body,
html {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}
body ::-moz-selection,
html ::-moz-selection {
  /* Code for Firefox */
  background: #0e9f6e;
}
body ::selection,
html ::selection {
  background: #0e9f6e;
}
html {
  scroll-behavior: smooth;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
}
h1 {
  margin: 0px;
}
section {
  padding: 4em 2em;
  text-align: center;
}
button {
  background: #74b72e;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin-top: 4rem;
  padding: 18px 35px;
  width: 200px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  margin-bottom: 4em;
}
button :hover {
  background: #046c4e;
  color: #fff;
  border: #fff;
}
/*
=====
DEPENDENCES
=====
*/
.r-link {
  display: var(--rLinkDisplay, inline-flex) !important;
}
.r-link[href] {
  color: var(--rLinkColor) !important;
  text-decoration: var(--rLinkTextDecoration, none) !important;
}
.r-list {
  padding-left: var(--rListPaddingLeft, 0) !important;
  margin-top: var(--rListMarginTop, 0) !important;
  margin-bottom: var(--rListMarginBottom, 0) !important;
  list-style: var(--rListListStyle, none) !important;
}
/*
  =====
  CORE STYLES
  =====
  */
.menu {
  --rLinkColor: var(--menuLinkColor, currentColor);
  display: flex;
  height: fit-content;
}
.menu__link {
  display: var(--menuLinkDisplay, block);
}
/* 
  focus state 
  */
.menu__link:focus {
  outline: var(--menuLinkOutlineWidth, 2px) solid var(--menuLinkOutlineColor, currentColor);
  outline-offset: var(--menuLinkOutlineOffset);
}
/* 
  fading siblings
  */
.menu:hover .menu__link:not(:hover) {
  --rLinkColor: var(--menuLinkColorUnactive, rgba(22, 22, 22, 0.35));
}
/*
  =====
  PRESENTATION STYLES
  =====
  */
.menu {
  background-color: var(--menuBackgroundColor, transparent);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu__list {
  display: flex;
}
.menu__link {
  padding: var(--menuLinkPadding, 1.5rem 2.5rem);
  font-weight: 700;
  text-transform: uppercase;
}
/* 
  =====
  TEXT UNDERLINED
  =====
  */
.text-underlined {
  position: relative;
  overflow: hidden;
  text-align-last: center;
  will-change: color;
  transition: color 0.25s ease-out;
}
.text-underlined::before,
.text-underlined::after {
  content: "";
  width: 0;
  height: 3px;
  background-color: var(--textUnderlinedLineColor, currentColor);
  will-change: width;
  transition: width 0.1s ease-out;
  position: absolute;
  bottom: 0;
}
.text-underlined::before {
  left: 50%;
  transform: translateX(-50%);
}
.text-underlined::after {
  right: 50%;
  transform: translateX(50%);
}
.text-underlined:hover::before,
.text-underlined:hover::after {
  width: 100%;
  transition-duration: 0.2s;
}
.page {
  left: 0;
  box-sizing: border-box;
  max-width: 100%;
  position: fixed;
  margin: auto;
  z-index: 1000;
  color: #0e9f6e;
  right: 0;
}
.page h1 {
  padding-left: 15px;
}
.page__menu:nth-child(n + 2) {
  margin-top: 3rem;
}
.r-link {
  --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
  --uirLinkTextColor: var(--rLinkTextColor);
  --uirLinkTextDecoration: var(--rLinkTextDecoration, none);
  display: var(--uirLinkDisplay) !important;
  color: var(--uirLinkTextColor) !important;
  text-decoration: var(--uirLinkTextDecoration) !important;
}
.nav-container {
  background: #74b72e;
  height: 100vh;
  width: 15%;
  position: fixed;
  z-index: 20;
}
.nav-container a {
  color: white;
}
#icon:hover {
  color: #5da9e9;
}
header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2em;
  text-align: center;
}
header .logo {
  color: #fff;
  font-weight: bold;
  margin-bottom: 5em;
  padding-bottom: 50px;
}
header ul {
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: space-between;
}
header ul a {
  display: block;
  padding: 0 1em;
}
.logo {
  width: 100px;
}
.bubble .icon_title {
  display: none;
}
.bubble:hover .icon_title {
  display: initial;
}
.bubble:hover .icon {
  display: none;
}
.custom-select {
  display: inline-block;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /* Styling the select background */
}
.custom-select select {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  width: auto;
  margin: 0;
  padding: 0.75em 0.5em;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background-color: transparent;
  /* Styling the select text color */
  color: #74b72e;
  font-weight: 600;
  letter-spacing: 1px;
  /* removes the fucking native down arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
}
.custom-select:after {
  position: absolute;
  top: 1.25em;
  right: 1rem;
  /* Styling the down arrow */
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 0.25em solid transparent;
  border-right: 0.25em solid transparent;
  border-top: 0.375em solid #74b72e;
  pointer-events: none;
}
.grey {
  background-color: #fff !important;
}
nav {
  --ease: all 300ms ease;
  position: fixed;
  background: #f8f9fa;
  height: 72px;
  width: 100%;
  z-index: 1000;
  transition: var(--ease);
}
nav.fade-in {
  opacity: 1;
}
.home {
  text-align: center;
  background: url(./Pictures/GreyHouse.jpeg) no-repeat center center;
  background-size: cover;
  height: 70vh;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  text-shadow: 2px 2px 6px #000;
  font-weight: 700;
}
.home h1 {
  font-size: 3rem;
}
.home h1,
.home h3 {
  color: #fff;
  font-weight: bold;
}
.home .openingText {
  margin-bottom: 75px;
}
.wrapper {
  position: relative;
  max-height: 65vh;
  width: 100%;
}
.wrapper .content {
  position: absolute;
  z-index: 100;
  height: 35vh;
  left: 0;
  right: 0;
  text-align: center;
  top: 35%;
  color: #fff;
}
.wrapper .content h1 {
  font-weight: bold;
  font-size: 5rem;
  text-shadow: 5px 5px 10px #231f20;
}
.wrapper .content h3 {
  font-weight: bold;
  font-size: 2rem;
  text-shadow: 5px 5px 10px #231f20;
}
.wrapper .background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}
.carousel .carousel-item {
  max-height: 65vh;
}
#AboutUs {
  margin-top: 10vh;
  scroll-margin-top: 180px;
}
#AboutUs h1 {
  font-weight: bold;
  color: #74b72e;
}
.hero {
  text-align: center;
  padding: 0em 5em;
  height: 75vh;
  justify-content: space-evenly;
  display: flex;
  place-items: center;
}
.hero .content {
  width: 40%;
}
.hero .content .stagger1 {
  margin-top: 10%;
}
.hero .content h1 {
  font-size: 3em;
  line-height: 1.2em;
}
section {
  padding: 4em;
}
.aboutContent {
  width: 50%;
  line-height: 1.8em;
  font-weight: 500;
  font-size: 1.2em;
  color: #3b3b3b;
}
.social-header {
  height: 25vh;
  margin-top: 4em;
}
.social-header ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  height: 20vh;
  align-items: center;
  padding-top: 10em;
}
.scroll {
  width: 30px;
  margin-top: 2em;
}
.Transition {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.61), rgba(0, 0, 0, 0.61)), url("./Pictures/GreyHouse.jpeg");
  color: white;
  background-color: transparent;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
  height: fit-content;
  margin-bottom: 10vh;
  letter-spacing: 2px;
}
.Transition h1 {
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 6px #000;
}
.Transition p {
  width: 70%;
}
.Transition img {
  height: 150px;
  width: 250px;
  margin: 1px 35px;
}
.Transition .synchrony {
  border-radius: 50%;
  background: #fff;
}
.ServiceSection {
  height: fit-content;
}
.ServiceSection .serviceTitle {
  text-align: center;
  margin-top: 5vh;
  font-weight: bold;
  color: #046c4e;
}
.pic {
  width: 40%;
  text-align: center;
}
.serviceContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2em;
}
.phone {
  display: none;
}
.serviceContent {
  width: 40%;
  text-align: center;
  margin-top: 2em;
  align-self: center;
}
.serviceContent span {
  color: #0e9f6e;
  text-decoration: underline;
}
.serviceContent h1 {
  font-weight: bold;
  color: #0e9f6e;
  margin-bottom: 1em;
}
.serviceContent p {
  font-weight: 700;
}
.pic img {
  height: 35vh;
  width: 35vh;
  border-radius: 50%;
  background: #0e9f6e;
}
.pic img:hover {
  background: #0e9f6e;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
  opacity: 0.7;
}
#ContactMe {
  scroll-margin-top: 190px;
}
.ContactForm {
  margin: 50px auto;
  text-align: center;
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}
.ContactForm h1 {
  display: flex;
  flex-direction: column;
}
.ContactForm h1 span {
  color: #046c4e;
  padding-left: 0.1em;
}
.ContactForm form {
  width: 60%;
}
.Hours {
  align-self: center;
}
.Hours h1 {
  color: #0e9f6e;
}
.Hours h1,
.Hours h2,
.Hours h3,
.Hours h4 {
  font-weight: bold;
}
.contactWrap {
  display: flex;
  flex-direction: column;
}
.contactTitle {
  justify-content: center;
  align-self: center;
}
.contactTitle h1 {
  font-weight: bold;
}
.contactTitle span {
  color: #74b72e;
}
input,
textarea {
  width: 75%;
  border: 2px solid black;
  border-radius: 5px;
  min-height: 2em;
}
.Container {
  width: 100%;
}
.AboutMe {
  width: 100%;
}
.error {
  color: #74b72e;
}
.Email {
  font-weight: bold;
  color: #818a91;
  text-align-last: center;
}
.Navflex {
  display: flex;
}
.serviceWrap {
  display: flex;
  justify-content: center;
  margin: 6rem 0rem;
  height: 70vh;
}
.serviceWrap .serviceCopy {
  width: 45%;
  text-align: center;
  align-self: center;
  font-weight: 500;
}
.serviceWrap img {
  width: 30%;
  border-radius: 50%;
  height: 65%;
  align-self: center;
}
.circle {
  animation: circleAnim 1s infinite alternate-reverse;
}
@keyframes circleAnim {
  from {
    transform: translate(286px, 581px);
    z-index: 5;
  }
  to {
    transform: translate(286px, 630px);
    z-index: 5;
  }
}
.arrow {
  animation: arrowAnim 1s infinite alternate-reverse;
}
@keyframes arrowAnim {
  from {
    transform: translate(5.436px, 9.082px);
    z-index: 5;
  }
  to {
    transform: translate(5.436px, 50px);
    z-index: 5;
  }
}
.site-footer {
  background-color: #046c4e;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #f8f9fa;
}
.row {
  display: flex;
  justify-content: space-evenly;
}
.row ul,
.row li {
  color: #f8f9fa;
}
.col-sm-12 {
  width: 45%;
}
.col-xs-6 {
  width: 25%;
}
.site-footer hr {
  border-top-color: #fff;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  color: #f8f9fa;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #f8f9fa;
}
.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: #f8f9fa;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}
.copyright-text {
  margin: 0;
  text-align: center;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #f8f9fa;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
@media only screen and (max-width: 450px) {
  #navbar {
    display: none;
  }
  .wrapper {
    min-height: 45vh;
    max-height: 45vh;
  }
  .wrapper .content {
    top: 0;
    min-height: 45vh;
    max-height: 45vh;
  }
  .wrapper .content h1 {
    font-size: 3.5em;
  }
  .wrapper .carousel .carousel-item {
    min-height: 45vh;
  }
  .serviceContainer {
    flex-direction: column;
    align-items: center;
  }
  .desktop {
    display: none;
  }
  .pic {
    width: auto;
  }
  .serviceContent {
    width: 80%;
    text-align: center;
  }
  #AboutUs {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  #AboutUs h1 {
    margin-bottom: 1em;
  }
  .hero {
    padding: 0;
    height: fit-content;
    margin-right: 2em;
  }
  .hero .stagger1 {
    display: none;
  }
  .aboutContent {
    width: auto;
  }
  .contactTitle {
    text-align: center;
  }
  .contactForm {
    width: 95%;
  }
  .Hours {
    align-self: unset;
    margin-top: 4em;
  }
  .Hours h1,
  .Hours h2,
  .Hours h3,
  .Hours h4 {
    font-size: 1.3rem;
  }
  #ContactMe {
    display: flex;
    flex-direction: column-reverse;
  }
  #ContactMe form {
    width: 100%;
  }
  .Transition img {
    height: 75px;
    width: 130px;
  }
  .Transition .transitionImgs {
    display: flex;
  }
}
